import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ContentContainer, HeaderContainer } from "./styled";
import * as yup from 'yup';
import { useFormik } from "formik";
import { apiGetUsers } from "../../../../Api/UserApi";
import { User, UserRole } from "../../../../Models/User";
import MoneyInput from "../../../../Components/MoneyInput";
import { Revenue } from "../../../../Models/Revenue";
import { apiCreateRevenue, apiEditRevenue } from "../../../../Api/RevenueApi";
import { apiGetAllBusiness } from "../../../../Api/BusinessApi";
import { Business } from "../../../../Models/Business";
import { MinValidDate } from "../../../../Models/Settings";
import DatePicker from "../../../../Components/DatePicker";

interface SetRevenueModalProps {
    isOpen: boolean
    onClose: () => void
    model?: Revenue
}

const validationSchema = yup.object({
    user: yup.string().required('Выберите пользователя'),
    business: yup.string().required('Выберите бизнес'),
    project: yup.string().required('Выберите проект'),
    amount: yup.number().min(1, 'Сумма должна быть больше 1 ₽').required('Введите сумму').typeError('Пожалуйста введите число'),
    date: yup.date().required('Введите дату операции').min(MinValidDate, 'Дата должна быть больше 01.01.2000').typeError('Пожалуйста введите дату'),
    comment: yup.string(),
})

const SetRevenueModal = ({ onClose, isOpen, model }: SetRevenueModalProps) => {
    const [users, setUsers] = useState<User[]>([])
    const [business, setBusiness] = useState<Business[]>([])
    const formik = useFormik({
        initialValues: {
            user: model?.user.login ?? '',
            business: model?.business.id ?? '',
            project: model?.project.id ?? '',
            amount: model?.amount,
            date: model?.date,
            comment: model?.comment ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => handleSend(),
    });

    const headerText = model ? 'Редактировать приход' : 'Новая запись о приходе'

    useEffect(() => {
        Promise.all([
            apiGetUsers()
                .then(resp => setUsers((resp.data ?? []).filter((x: User) => x.roles.includes(UserRole.Manager) && !x.isDeleted)))
                .catch(err => console.log(err)),
            apiGetAllBusiness()
                .then(res => setBusiness(res.data.filter((x: any) => !x.isDeleted) ?? []))
                .catch(err => console.log(err)),
        ])
    }, [])

    const handleSend = () => {
        const values = formik.values
        if (!values.date) return
        let promise
        if (model) {
            promise = apiEditRevenue({
                id: model.id,
                comment: values.comment,
                date: values.date
            })
        } else {
            promise = apiCreateRevenue({
                amount: values.amount ?? 0,
                comment: values.comment,
                userLogin: values.user,
                date: values.date,
                business: values.business,
                project: values.project,
            })
        }
        promise.then(() => handleClose()).catch(err => console.log(err))
    }

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const handleChangeBusiness = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`business`, value)
        formik.setFieldValue(`project`, '')
    }

    const handleChangeProject = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`project`, value)
    }

    const handleChangeUser = (event: SelectChangeEvent) => {
        const value = event.target.value
        formik.setFieldValue('user', value)
        formik.setFieldValue(`business`, '')
        formik.setFieldValue(`project`, '')
    }

    const handleChangeDate = (newValue: Date | null) => {
        if (newValue != null) {
            let date = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000)
            formik.setFieldValue("date", date)
        }
    }

    const selectedUser = users.find(x => x.login === formik.values.user)
    const userBusiness = business.filter(x => !x.isDeleted && x.projects.some(pr => selectedUser?.projectIds.includes(pr.id))) ?? []
    const selectedBusiness = business.find(x => x.id === formik.values.business)
    const userProjects = selectedBusiness?.projects.filter(x => !x.isDeleted && selectedUser?.projectIds.includes(x.id)) ?? []

    return <Modal
        open={isOpen}
        onClose={handleClose}
    >
        <ContentContainer>
            <HeaderContainer>
                <h4>{headerText}</h4>
                <Button variant="text" onClick={handleClose}>Закрыть</Button>
            </HeaderContainer>

            <FormControl fullWidth disabled={!!model} error={formik.touched.user && Boolean(formik.errors.user)}>
                <InputLabel htmlFor="user">Пользователь</InputLabel>
                <Select
                    variant="outlined"
                    id="user"
                    value={formik.values.user ?? ''}
                    label="Пользователь"
                    onChange={handleChangeUser}
                >
                    {users.map(x => <MenuItem key={x.id} value={x.login}>{x.name}</MenuItem>)}
                </Select>
                <FormHelperText>{formik.touched.user && formik.errors.user}</FormHelperText>
            </FormControl>

            <FormControl fullWidth disabled={!!model || !selectedUser} error={formik.touched.business && Boolean(formik.errors.business)}>
                <InputLabel htmlFor="business">Бизнес</InputLabel>
                <Select
                    id="business"
                    variant="outlined"
                    value={formik.values.business ?? ''}
                    label="Бизнес"
                    onChange={handleChangeBusiness}
                >
                    {userBusiness.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                </Select>
                <FormHelperText>{formik.touched.business && formik.errors.business}</FormHelperText>
            </FormControl>
            <FormControl
                fullWidth
                disabled={!selectedBusiness || !!model} error={formik.touched.business && Boolean(formik.errors.business)}
            >
                <InputLabel htmlFor="project">Проект</InputLabel>
                <Select
                    id="project"
                    variant="outlined"
                    value={formik.values.project ?? ''}
                    label="Проект"
                    onChange={handleChangeProject}
                >
                    {userProjects.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                </Select>
                <FormHelperText>{formik.touched.project && formik.errors.project}</FormHelperText>
            </FormControl>

            <FormControl fullWidth>
                <MoneyInput
                    id="amount"
                    label="Сумма"
                    disabled={!!model}
                    value={formik.values.amount}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]', }}
                    InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
                    ChangeCallback={(val: number | undefined) => formik.setFieldValue("amount", val)}
                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                    helperText={formik.touched.amount && formik.errors.amount}
                />
            </FormControl>
            <FormControl fullWidth error={formik.touched.date && Boolean(formik.errors.date)}>
                <DatePicker
                    label="Дата операции"
                    value={formik.values.date ?? null}
                    onChange={date => formik.setFieldValue("date", date)}
                />
                <FormHelperText>{formik.touched.date && formik.errors.date}</FormHelperText>
            </FormControl>

            <FormControl>
                <TextField
                    id="comment"
                    label="Комментарий"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    multiline
                    rows={4}
                    variant="outlined"
                />
            </FormControl>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
        </ContentContainer>
    </Modal>
}

export default SetRevenueModal
