import React, { useEffect, useState } from 'react';
import './App.css';
import Pages from "./Pages";
import {AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {ThemeProvider, createTheme} from '@mui/material/styles';
import { InitializeConstants } from "./Models/Constants";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru)
setDefaultLocale('ru')

// @ts-ignore
let telegramObject = window.Telegram.WebApp
// @ts-ignore
const theme = createTheme({
    palette: {
        mode: telegramObject?.colorScheme ?? 'dark',
    },
});

function App() {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "bearer " + localStorage.getItem('token')
        InitializeConstants().then(resp => setIsLoading(false))
    }, [])
    if(isLoading) return <>...Loading</>

    return (
        <div className="App">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <ThemeProvider theme={theme}>
                    <Pages/>
                </ThemeProvider>
            </LocalizationProvider>
        </div>
    );
}

export default App;
