import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button } from "@mui/material";
import { Project, ProjectWithBusiness } from "../../../../Models/Business";
import { apiGetAllProjectWithBusiness, apiToggleDeleteProject } from "../../../../Api/BusinessApi";
import SetProjectModal from "./SetProject";
import Table from "../../../../Components/Table";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";
import ConstantHelper from "../../../../Models/Constants";


interface ModalState {
    open: boolean
    editModel?: Project
}

const ProjectsTab = () => {
    const [projects, setProjects] = useState<ProjectWithBusiness[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })

    useEffect(() => loadData(), [])

    const columns = useMemo<MRT_ColumnDef<ProjectWithBusiness>[]>(() => [
        {
            id: 'name',
            accessorKey: 'name',
            header: 'Имя',
            meta: 'string',
        },
        {
            id: 'business',
            accessorKey: 'business',
            header: 'Бизнес',
            accessorFn: (value: ProjectWithBusiness) => value.business.name,
            meta: 'string',
        },
        {
            id: 'balances',
            accessorKey: 'balances',
            header: 'Баланс',
            accessorFn: (row) => row.balances.reduce((a, b) => a + b.amount, 0),
            Cell: ({ cell }) => ConstantHelper.Formatter.format(cell.getValue<number>()),
            meta: 'number',
            size: 150,
        },
    ], [])

    const handleEditCell = (item: ProjectWithBusiness) => {
        setModalState({ open: true, editModel: item as unknown as Project })
    }

    const handleToggleDeleteItem = (item: ProjectWithBusiness) => {
        apiToggleDeleteProject(item.id).then(() => loadData()).catch(err => console.log(err))
    }

    const loadData = () => {
        apiGetAllProjectWithBusiness()
            .then(resp => setProjects(resp))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }


    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }

    return <Container>
        <Button variant="outlined" sx={{ width: '250px', marginBottom: '20px' }} onClick={() => setModalState({ open: true })}>Добавить проект</Button>
        <Table
            columns={columns}
            data={projects}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
        />
        {modalState.open && <SetProjectModal isOpen={modalState.open} project={modalState.editModel} onClose={closeModal}/>}
    </Container>
}

export default ProjectsTab
