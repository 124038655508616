import styled from "@emotion/styled"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  //background-color: 'palete.background';
  //background-color: #212121;
  & > * {
    margin-top: 15px !important;
  }
  
  .MuiListItemText-root {
    margin: 0 !important;
  }
`
