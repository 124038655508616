import React, { useEffect, useState } from 'react'
import { Container } from "./styled";
import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import MoneyInput from "../../../Components/MoneyInput";
import { apiCreateRevenue } from "../../../Api/RevenueApi";
import { apiGetAllBusiness } from "../../../Api/BusinessApi";
import { Business } from "../../../Models/Business";
import { User } from "../../../Models/User";
import { apiGetUser } from "../../../Api/UserApi";
import { MinValidDate } from "../../../Models/Settings";
import DatePicker from "../../../Components/DatePicker";

const validationSchema = yup.object({
    business: yup.string().required('Выберите бизнес'),
    project: yup.string().required('Выберите проект'),
    amount: yup.number().min(1, 'Сумма должна быть больше 1 ₽').required('Введите сумму').typeError('Пожалуйста введите число'),
    date: yup.date().required('Введите дату операции').min(MinValidDate, 'Дата должна быть больше 01.01.2000').typeError('Пожалуйста введите дату'),
    comment: yup.string(),
})

const AddRevenuePage = ({}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [business, setBusiness] = useState<Business[]>([])
    const [user, setUser] = useState<User>();

    const formik = useFormik({
        initialValues: {
            business: '',
            project: '',
            amount: null,
            date: null,
            comment: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // @ts-ignore
            let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username
            apiCreateRevenue({
                amount: values.amount!,
                comment: values.comment,
                userLogin: userLogin,
                business: values.business,
                project: values.project,
                date: values.date!,
            })
                // @ts-ignore
                .finally(() => window.Telegram?.WebApp?.close())
        },
    });

    useEffect(() => {
        // @ts-ignore
        let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username
        Promise.all([
            apiGetUser(userLogin)
                .then(res => setUser(res)),
            apiGetAllBusiness()
                .then(res => setBusiness(res.data.filter((x: any) => !x.isDeleted) ?? []))
                .catch(err => console.log(err))
        ]).finally(() => setIsLoading(false))

        // @ts-ignore
        window?.Telegram?.WebApp?.expand()
        const height = window.visualViewport.height;
        const viewport = window.visualViewport
    }, [])

    const handleChangeDate = (newValue: Date | null) => {
        if (newValue != null) {
            let date = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000)
            formik.setFieldValue("date", date)
        }
    }

    const handleChangeBusiness = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`business`, value)
        formik.setFieldValue(`project`, '')
    }

    const handleChangeProject = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`project`, value)
    }

    const userBusiness = business.filter(x => !x.isDeleted && x.projects.some(pr => user?.projectIds?.includes(pr.id) ?? false)) ?? []
    const selectedBusiness = business.find(x => x.id === formik.values.business)
    const userProjects = selectedBusiness?.projects.filter(x => !x.isDeleted && (user?.projectIds?.includes(x.id) ?? false)) ?? []

    if (isLoading) return <div>Loading...</div>
    return (<Container>
        <FormControl fullWidth error={formik.touched.business && Boolean(formik.errors.business)}>
            <InputLabel htmlFor="business">Бизнес</InputLabel>
            <Select
                id="business"
                variant="outlined"
                value={formik.values.business ?? ''}
                label="Бизнес"
                onChange={handleChangeBusiness}
            >
                {userBusiness.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
            <FormHelperText>{formik.touched.business && formik.errors.business}</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth
            disabled={!selectedBusiness} error={formik.touched.business && Boolean(formik.errors.business)}
        >
            <InputLabel htmlFor="project">Проект</InputLabel>
            <Select
                id="project"
                variant="outlined"
                value={formik.values.project ?? ''}
                label="Проект"
                onChange={handleChangeProject}
            >
                {userProjects.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
            <FormHelperText>{formik.touched.project && formik.errors.project}</FormHelperText>
        </FormControl>
        <FormControl fullWidth>
            <MoneyInput
                id="amount"
                label="Сумма"
                value={formik.values.amount}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]', }}
                InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
                ChangeCallback={(val: number | undefined) => formik.setFieldValue("amount", val)}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
            />
        </FormControl>
        <FormControl fullWidth error={formik.touched.date && Boolean(formik.errors.date)}>
            <DatePicker
                label="Дата операции"
                value={formik.values.date ?? null}
                onChange={date => formik.setFieldValue("date", date)}
            />
            <FormHelperText>{formik.touched.date && formik.errors.date}</FormHelperText>
        </FormControl>

        <FormControl>
            <TextField
                id="comment"
                label="Комментарий"
                value={formik.values.comment}
                onChange={formik.handleChange}
                multiline
                rows={4}
                variant="outlined"
            />
        </FormControl>
        <Button id="submit_button" variant="outlined" fullWidth onClick={() => formik.handleSubmit()}>Отправить</Button>
    </Container>)

};

export default AddRevenuePage
