import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: calc(100vh - 52px);
  & > * {
    margin-top: 15px !important;
  }
`
