import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ContentContainer, HeaderContainer } from "./styled";
import * as yup from 'yup';
import { useFormik } from "formik";
import { apiGetUsers, apiSetUser } from "../../../../Api/UserApi";
import { User, UserRole } from "../../../../Models/User";
import { ProjectWithBusiness } from "../../../../Models/Business";
import { apiGetAllProjectWithBusiness } from "../../../../Api/BusinessApi";

interface IAddUserProps {
    isOpen: boolean
    onClose: () => void
    user?: User
}

const SetUserModal = ({ onClose, isOpen, user }: IAddUserProps) => {
    const [users, setUsers] = useState<User[]>([])
    const [projects, setProjects] = useState<ProjectWithBusiness[]>([])
    const validationSchema = yup.object({
        name: yup.string().required('Введите имя'),
        login: yup.string().required('Введите логин')
            .test('unique login', 'Пользователь с таким логином уже есть', (value) => !users.filter(x => x.login !== user?.login).some(x => x.login === value)),
        projects: yup.array(yup.string()).min(1, "Выберите доступные проекты"),
    })

    const formik = useFormik({
        initialValues: {
            name: user?.name ?? '',
            login: user?.login ?? '',
            projects: user?.projectIds ?? [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiSetUser({
                id: user?.id,
                login: values.login,
                name: values.name,
                roles: [UserRole.Manager],
                projectIds: values.projects,
            })
                .then(() => handleClose())
                .catch(err => console.log(err))
        },
    });

    useEffect(() => {
        Promise.all([
            apiGetUsers().then(resp => {
                setUsers(resp.data ?? [])
            }),
            apiGetAllProjectWithBusiness()
                .then(res => setProjects(res.filter(x => !x.isDeleted) ?? []))
                .catch(err => console.log(err)),
        ])
    }, [])

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }
    const handleChangeProjects = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;
        console.log(value)
        formik.setFieldValue("projects", value)
    };

    return <Modal
        open={isOpen}
        onClose={handleClose}
    >
        <ContentContainer>
            <HeaderContainer>
                <h4>Новый пользователь</h4>
                <Button variant="text" onClick={handleClose}>Закрыть</Button>
            </HeaderContainer>

            <FormControl>
                <TextField
                    id="name"
                    label="Имя"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </FormControl>

            <FormControl>
                <TextField
                    id="login"
                    label="Логин"
                    value={formik.values.login}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.login && Boolean(formik.errors.login)}
                    helperText={formik.touched.login && formik.errors.login}
                />
            </FormControl>

            <FormControl
                fullWidth
                error={formik.touched.projects && Boolean(formik.errors.projects)}
            >
                <InputLabel htmlFor="project">Проект</InputLabel>
                <Select
                    id="project"
                    variant="outlined"
                    multiple
                    value={formik.values.projects ?? []}
                    label="Проект"
                    onChange={handleChangeProjects}
                >
                    {projects.map(x => <MenuItem key={x.id} value={x.id}>{x.business.name} - {x.name}</MenuItem>)}
                </Select>
                <FormHelperText>{formik.touched.projects && formik.errors.projects}</FormHelperText>
            </FormControl>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
        </ContentContainer>
    </Modal>
}

export default SetUserModal
