import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button } from "@mui/material";
import SetAdminModal from "./SetAdmin";
import Table from "../../../Components/Table";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";
import { Admin } from "../../../Models/Admin";
import { apiGetAdmins, apiToggleDeleteAdmin } from "../../../Api/AdminApi";
import { Expenses } from "../../../Models/Expenses";

interface ModalState {
    open: boolean
    editModel?: Admin
}

const AdministratorsPage = () => {
    const [admins, setAdmins] = useState<Admin[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })

    useEffect(() => loadData(), [])

    const columns = useMemo<MRT_ColumnDef<Admin>[]>(() => [
        {
            id: 'login',
            accessorKey: 'login',
            header: 'Логин',
        },
        {
            id: 'isDeleted',
            accessorKey: 'isDeleted',
            header: 'Удален',
            accessorFn: (value: Admin) => value.isDeleted ? 'Да' : 'Нет',
            size: 100,
        },
    ], [])

    const handleEditCell = (item: Admin) => {
        setModalState({ open: true, editModel: item })
    }

    const handleToggleDeleteItem = (item: Admin) => {
        apiToggleDeleteAdmin(item.id).then(() => loadData()).catch(err => console.log(err))
    }

    const loadData = () => {
        setIsLoading(true)
        apiGetAdmins().then(resp => {
            setAdmins(resp.data ?? [])
            setIsLoading(false)
        })
    }
    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }
    return <Container>
        <Button
            variant="outlined"
            sx={{ width: '250px' }}
            onClick={() => setModalState({ open: true })}
        >
            Добавить администратора
        </Button>
        <Table
            columns={columns}
            data={admins}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
        />
        {modalState.open && <SetAdminModal isOpen={modalState.open} admin={modalState.editModel} onClose={closeModal}/>}
    </Container>
}

export default AdministratorsPage
