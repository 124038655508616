import axios from "axios";
import { UserRole } from "../Models/User";

export const apiAuthorize = (login: string, password: string) => axios.post('/api/admin', { login, password })

export const apiGetAdmins = () => axios.get("/api/admin/all")

export const apiSetAdmin = (login: string, password?: string, id?: string) =>
    axios.post(`/api/admin/set`, { id, login, password })

export const apiToggleDeleteAdmin = (id: string) => axios.post(`/api/admin/${id}/toggleIsDelete`)
