import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Box, Button } from "@mui/material";
import { User } from "../../../Models/User";
import { apiGetUsers, apiToggleDeleteUser, apiUpdateUsersBalance } from "../../../Api/UserApi";
import SetUserModal from "./SetUser";
import Table from "../../../Components/Table";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";
import ConstantHelper from "../../../Models/Constants";
import { CellExpand } from "../../../Components/CellExpand";
import { ProjectWithBusiness } from "../../../Models/Business";
import { apiGetAllProjectWithBusiness } from "../../../Api/BusinessApi";
import GetReportModal from "./GetReport";

interface ModalState {
    open: boolean
    editModel?: User
}

const UsersPage = () => {
    const [users, setUsers] = useState<User[]>([])
    const [projects, setProjects] = useState<ProjectWithBusiness[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })
    const [showReportModal, setShowReportModal] = useState(false)

    useEffect(() => loadData(), [])

    const columns = useMemo<MRT_ColumnDef<User>[]>(() => [
        {
            id: 'login',
            accessorKey: 'login',
            header: 'Логин',
            meta: 'string',
        },
        {
            id: 'name',
            accessorKey: 'name',
            header: 'Имя',
            meta: 'string',
        },
        {
            id: 'balances',
            accessorKey: 'balances',
            header: 'Баланс',
            accessorFn: (row) => row.balances.reduce((a, b) => a + b.amount, 0),
            Cell: ({ cell }) => ConstantHelper.Formatter.format(cell.getValue<number>()),
            meta: 'number',
            size: 150,
        },
        {
            id: 'projectIds',
            accessorKey: 'projectIds',
            header: 'Доступные проекты',
            accessorFn: (row) => projects.filter(x => row.projectIds.includes(x.id)).map(x => `${x.business.name} - ${x.name}`).join(", "),
            Cell: ({ cell, table }) => <CellExpand value={cell.getValue()} width={200}/>,
            size: 200,
            meta: 'string',
        },
    ], [projects])

    const handleEditCell = (item: User) => {
        setModalState({ open: true, editModel: item })
    }

    const handleToggleDeleteItem = (item: User) => {
        apiToggleDeleteUser(item.id).then(() => loadData()).catch(err => console.log(err))
    }
    const loadData = () => {
        setIsLoading(true)
        apiGetAllProjectWithBusiness()
            .then(res => setProjects(res.filter(x => !x.isDeleted) ?? []))
            .catch(err => console.log(err))
            .then(() => apiGetUsers().then(resp => {
                setUsers(resp.data ?? [])
                setIsLoading(false)
            }))
    }

    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }
    if (isLoading) return <div>Loading...</div>
    return <Container>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
                variant="outlined"
                sx={{ width: '250px' }}
                onClick={() => setModalState({ open: true })}
            >
                Добавить пользователя
            </Button>
            <Button
              variant="outlined"
              sx={{ width: '250px', marginLeft: '10px' }}
              onClick={() => setShowReportModal(true)}
            >
                Сформировать отчет
            </Button>
        </Box>
        <Table
            columns={columns}
            data={users}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
        />
        {modalState.open && <SetUserModal isOpen={modalState.open} user={modalState.editModel} onClose={closeModal}/>}
        {showReportModal && <GetReportModal isOpen={showReportModal} onClose={() => setShowReportModal(false)} />}
    </Container>
}

export default UsersPage
