import React from 'react'
import { Button, FormControl, Modal, TextField } from "@mui/material";
import { ContentContainer, HeaderContainer } from "./styled";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Business } from "../../../../../Models/Business";
import { apiSetBusiness } from "../../../../../Api/BusinessApi";

interface ISetBusinessModalProps {
    isOpen: boolean
    onClose: () => void
    business?: Business
}

const validationSchema = yup.object({
    name: yup.string().required('Введите название'),
})

const SetBusinessModal = ({ onClose, isOpen, business }: ISetBusinessModalProps) => {

    const formik = useFormik({
        initialValues: {
            name: business?.name ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiSetBusiness(values.name, business?.id).then(handleClose).catch(err => console.log(err))
        },
    });

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    return <Modal
        open={isOpen}
        onClose={handleClose}
    >
        <ContentContainer>
            <HeaderContainer>
                <h4>Новый бизнес</h4>
                <Button variant="text" onClick={handleClose}>Закрыть</Button>
            </HeaderContainer>

            <FormControl>
                <TextField
                    id="name"
                    label="Название бизнеса"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </FormControl>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
        </ContentContainer>
    </Modal>
}

export default SetBusinessModal
