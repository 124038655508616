import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ContentContainer, HeaderContainer, RowContainer } from "./styled";
import * as yup from 'yup';
import { useFormik } from "formik";
import { apiGetUsers } from "../../../../Api/UserApi";
import { User, UserRole } from "../../../../Models/User";
import { Expenses } from "../../../../Models/Expenses";
import { apiGetAllAnalytics } from "../../../../Api/AnalyticsApi";
import { AnalyticParameters } from "../../../../Models/AnalyticParameters";
import { Business } from "../../../../Models/Business";
import { apiGetAllBusiness } from "../../../../Api/BusinessApi";
import { apiCreateExpenses, apiEditExpenses } from "../../../../Api/ExpensesApi";
import MoneyInput from "../../../../Components/MoneyInput";
import { MinValidDate } from "../../../../Models/Settings";
import DatePicker from "../../../../Components/DatePicker";

interface SetExpensesModalProps {
    isOpen: boolean
    onClose: () => void
    expenses?: Expenses
}

const validationSchema = yup.object({
    manager: yup.string().required('Выберите отправителя'),
    business: yup.string().required('Выберите бизнес'),
    project: yup.string().required('Выберите проект'),
    amount: yup.number().min(1, 'Сумма должна быть больше 1 ₽').required('Введите сумму').typeError('Пожалуйста введите число'),
    operationDate: yup.date().required('Введите дату операции').min(MinValidDate, 'Дата должна быть больше 01.01.2000').typeError('Пожалуйста введите дату'),
    comment: yup.string(),
    analyticIds: yup.array(yup.string()),
})

const SetExpensesModal = ({ onClose, isOpen, expenses }: SetExpensesModalProps) => {
    const [managers, setManagers] = useState<User[]>([])
    const [business, setBusiness] = useState<Business[]>([])
    const [analytics, setAnalytics] = useState<AnalyticParameters[]>([])
    const formik = useFormik({
        initialValues: {
            manager: expenses?.manager.login ?? '',
            business: expenses?.business.id ?? '',
            project: expenses?.project.id ?? '',
            amount: expenses?.amount,
            operationDate: expenses?.operationDate,
            comment: expenses?.comment ?? '',
            analyticIds: expenses?.analyticIds ?? [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => handleSend(),
    });

    const headerText = expenses ? 'Редактировать расход' : 'Новый расход'

    useEffect(() => {
        Promise.all([
            apiGetUsers()
                .then(resp => setManagers((resp.data ?? []).filter((x: User) => x.roles.includes(UserRole.Manager) && !x.isDeleted)))
                .catch(err => console.log(err)),
            apiGetAllAnalytics()
                .then(res => setAnalytics(res.data.filter((x: any) => !x.isDeleted) ?? []))
                .catch(err => console.log(err)),
            apiGetAllBusiness()
                .then(res => setBusiness(res.data.filter((x: any) => !x.isDeleted) ?? []))
                .catch(err => console.log(err)),
        ])
    }, [])

    const handleSend = () => {
        const values = formik.values
        if (!values.operationDate) return
        let promise
        if (expenses) {
            promise = apiEditExpenses({
                id: expenses.id,
                analyticParameters: values.analyticIds,
                comment: values.comment,
                operationDate: values.operationDate
            })
        } else {
            promise = apiCreateExpenses({
                business: values.business,
                amount: values.amount ?? 0,
                comment: values.comment,
                managerLogin: values.manager,
                analyticParameters: values.analyticIds,
                project: values.project,
                operationDate: values.operationDate,
            })
        }
        promise.then(() => handleClose()).catch(err => console.log(err))
    }

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const handleChangeSelectUser = (event: SelectChangeEvent) => {
        const value = event.target.value
        formik.setFieldValue('manager', value)
        formik.setFieldValue(`business`, '')
        formik.setFieldValue(`project`, '')
    }

    const handleChangeAnalytics = (level: number) => (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`analyticIds[${level - 1}]`, value)
        formik.setFieldValue(`analyticIds[${level}]`, '')
        formik.setFieldValue(`analyticIds[${level + 1}]`, '')
    }

    const handleChangeBusiness = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`business`, value)
        formik.setFieldValue(`project`, '')
    }

    const handleChangeProject = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`project`, value)
        formik.setFieldValue(`analyticIds[0]`, '')
        formik.setFieldValue(`analyticIds[1]`, '')
        formik.setFieldValue(`analyticIds[2]`, '')
    }

    const handleChangeDate = (newValue: Date) => {
        if (newValue != null) {
            formik.setFieldValue("operationDate", newValue)
        }
    }

    const getAnalytics = (level: number, parentId: string | null = null): JSX.Element[] => {
        return (analytics?.filter(x => x.parentId === parentId && x.level === level && x.projectId === formik.values.project) ?? [])
            .map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
    }

    const selectedUser = managers.find(x => x.login === formik.values.manager)
    const userBusiness = business.filter(x => !x.isDeleted && x.projects.some(pr => selectedUser?.projectIds.includes(pr.id))) ?? []
    const selectedBusiness = business.find(x => x.id === formik.values.business)
    const userProjects = selectedBusiness?.projects.filter(x => !x.isDeleted && selectedUser?.projectIds.includes(x.id)) ?? []

    return <Modal
        open={isOpen}
        onClose={handleClose}
    >
        <ContentContainer>
            <HeaderContainer>
                <h4>{headerText}</h4>
                <Button variant="text" onClick={handleClose}>Закрыть</Button>
            </HeaderContainer>
            <RowContainer>
                <FormControl fullWidth disabled={!!expenses} error={formik.touched.manager && Boolean(formik.errors.manager)}>
                    <InputLabel htmlFor="manager">Пользователь</InputLabel>
                    <Select
                        variant="outlined"
                        id="manager"
                        value={formik.values.manager ?? ''}
                        label="Пользователь"
                        onChange={handleChangeSelectUser}
                    >
                        {managers.map(x => <MenuItem key={x.id} value={x.login}>{x.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>{formik.touched.manager && formik.errors.manager}</FormHelperText>
                </FormControl>

                <FormControl fullWidth disabled={!!expenses || !selectedUser} error={formik.touched.business && Boolean(formik.errors.business)}>
                    <InputLabel htmlFor="business">Бизнес</InputLabel>
                    <Select
                        id="business"
                        variant="outlined"
                        value={formik.values.business ?? ''}
                        label="Бизнес"
                        onChange={handleChangeBusiness}
                    >
                        {userBusiness.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>{formik.touched.business && formik.errors.business}</FormHelperText>
                </FormControl>
            </RowContainer>
            <RowContainer>
                <FormControl
                    fullWidth
                    disabled={!selectedBusiness || !!expenses} error={formik.touched.business && Boolean(formik.errors.business)}
                >
                    <InputLabel htmlFor="project">Проект</InputLabel>
                    <Select
                        id="project"
                        variant="outlined"
                        value={formik.values.project ?? ''}
                        label="Проект"
                        onChange={handleChangeProject}
                    >
                        {userProjects.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>{formik.touched.project && formik.errors.project}</FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                    <MoneyInput
                        id="amount"
                        label="Сумма"
                        disabled={!!expenses}
                        value={formik.values.amount}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]', }}
                        InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
                        ChangeCallback={(val: number | undefined) => formik.setFieldValue("amount", val)}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                    />
                </FormControl>
            </RowContainer>
            <RowContainer>
                <FormControl fullWidth error={formik.touched.operationDate && Boolean(formik.errors.operationDate)}>
                    <DatePicker
                        label="Дата операции"
                        value={formik.values.operationDate ?? null}
                        onChange={date => formik.setFieldValue("operationDate", date)}
                    />
                    <FormHelperText>{formik.touched.operationDate && formik.errors.operationDate}</FormHelperText>
                </FormControl>

                <FormControl fullWidth disabled={!formik.values.project}>
                    <InputLabel htmlFor="analytics1">Аналитика 1</InputLabel>
                    <Select
                        variant="outlined"
                        id="analytics1"
                        value={formik.values.analyticIds[0] ?? ''}
                        label="Аналитика 1"
                        onChange={handleChangeAnalytics(1)}
                    >
                        {getAnalytics(1)}
                    </Select>
                </FormControl>
            </RowContainer>
            <RowContainer>
                <FormControl fullWidth disabled={!formik.values.analyticIds[0]}>
                    <InputLabel htmlFor="analytics2">Аналитика 2</InputLabel>
                    <Select
                        variant="outlined"
                        id="analytics2"
                        value={formik.values.analyticIds[1] ?? ''}
                        label="Аналитика 2"
                        onChange={handleChangeAnalytics(2)}
                    >
                        {getAnalytics(2, formik.values.analyticIds[0])}
                    </Select>
                </FormControl>

                <FormControl fullWidth disabled={!formik.values.analyticIds[1]}>
                    <InputLabel htmlFor="analytics1">Аналитика 3</InputLabel>
                    <Select
                        variant="outlined"
                        id="analytics3"
                        value={formik.values.analyticIds[2] ?? ''}
                        label="Аналитика 3"
                        onChange={handleChangeAnalytics(3)}
                    >
                        {getAnalytics(3, formik.values.analyticIds[1])}
                    </Select>
                </FormControl>
            </RowContainer>
            <FormControl>
                <TextField
                    id="comment"
                    label="Комментарий"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    multiline
                    rows={4}
                    variant="outlined"
                />
            </FormControl>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
        </ContentContainer>
    </Modal>
}

export default SetExpensesModal
