import React, { useEffect } from 'react'
import { Navigate, Route } from "react-router-dom";
import {IndexRouteProps, LayoutRouteProps, PathRouteProps} from "react-router/lib/components";
import axios from "axios";
import { useNavigate } from "react-router";

const PrivateRoute = (props: PathRouteProps | LayoutRouteProps | IndexRouteProps) => {
    useEffect(() => {
        axios.interceptors.response.use(response => response,
            error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    window.location.href = '/admin/login'
                }
                return Promise.reject(error);
            })

    }, [])

    if (!!localStorage.getItem('token'))
        return <Route {...props}/>
    else
        return <Route index element={<Navigate to="/admin/login" replace/>}/>
}

export default PrivateRoute
