import React from 'react'
import BusinessTab from "./BusinessTab";
import { Box, Tab, Tabs } from "@mui/material";
import { Container, TabContainer } from "./styled";
import ProjectsTab from "./ProjectTab";
import AnalyticsTab from "./AnalyticsTab";

const CatalogPage = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <Container>
        <TabContainer>
            <Tabs value={value} onChange={handleChange} >
                <Tab label="Бизнесы" />
                <Tab label="Проекты"/>
                <Tab label="Аналитические параметры"/>
            </Tabs>
        </TabContainer>
        {value === 0 && <BusinessTab />}
        {value === 1 && <ProjectsTab />}
        {value === 2 && <AnalyticsTab />}
    </Container>
}

export default CatalogPage
