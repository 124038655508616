import axios from 'axios'
import {ICreateExpensesFormData} from "../Pages/Bot/ManagerAddExpensesPage";

export const apiCreateExpenses = (data: ApiCreateExpensesModel) =>
    axios.post("/api/expenses/create", {
        ...data,
        analyticParameters: data.analyticParameters.filter(x => !!x),
    })

export const apiEditExpenses = (data: ApiEditExpenses) =>
    axios.post("/api/expenses/edit", {
        ...data,
        analyticParameters: data.analyticParameters.filter(x => !!x),
    })

export const apiGetExpenses = (userLogin: string) => axios.get(`/api/expenses/${userLogin}`)

export const apiGetAllExpenses = () => axios.get(`/api/expenses/all`)

export const apiToggleDeleteExpenses = (id: string) => axios.post(`/api/expenses/${id}/toggleIsDelete`)

export interface ApiCreateExpensesModel {
    managerLogin: string
    business: string
    project: string
    amount: number
    operationDate: Date
    analyticParameters: string[]
    comment: string
}

interface ApiEditExpenses {
    id: string
    operationDate: Date
    analyticParameters: string[]
    comment: string
}
