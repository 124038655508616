import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ContentContainer, HeaderContainer } from "./styled";
import * as yup from 'yup';
import { useFormik } from "formik";
import { apiGetUsers, apiSetUser } from "../../../../Api/UserApi";
import { User, UserRole, UserRoleRus } from "../../../../Models/User";
import { Admin } from "../../../../Models/Admin";
import { apiGetAdmins, apiSetAdmin } from "../../../../Api/AdminApi";

interface IAddAdminProps {
    isOpen: boolean
    onClose: () => void
    admin?: Admin
}

const SetAdminModal = ({ onClose, isOpen, admin }: IAddAdminProps) => {
    const [admins, setAdmins] = useState<Admin[]>([])
    const validationSchema = yup.object({
        login: yup.string().required('Введите логин')
            .test('unique login', 'Пользователь с таким логином уже есть',
                (value) => !admins.filter(x => x.login !== admin?.login).some(x => x.login === value)),
        password: yup.string().nullable().min(6, 'Пароль должен быть больше 6 символов').max(20, 'Пароль должен быть меньше 20 символов')
            .test('required password', 'Введите пароль', (value) => !!value || !!admin),
        confirmPassword: yup.string().nullable().
            test('confirm password', 'Пароли не совпадают', (value, ctx) => value === ctx.parent.password),
    })

    const formik = useFormik({
        initialValues: {
            login: admin?.login ?? '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiSetAdmin(values.login, values.password, admin?.id)
                .then(() => handleClose())
                .catch(err => console.log(err))
        },
    });

    useEffect(() => {
        apiGetAdmins().then(resp => {
            setAdmins(resp.data ?? [])
        })
    }, [])
    console.log(admin)
    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const header = !admin ? 'Новый администратор' : 'Редактировать администратора'

    return <Modal
        open={isOpen}
        onClose={handleClose}
    >
        <ContentContainer>
            <HeaderContainer>
                <h4>{header}</h4>
                <Button variant="text" onClick={handleClose}>Закрыть</Button>
            </HeaderContainer>



            <FormControl>
                <TextField
                    id="login"
                    label="Логин"
                    value={formik.values.login}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.login && Boolean(formik.errors.login)}
                    helperText={formik.touched.login && formik.errors.login}
                />
            </FormControl>

            <FormControl>
                <TextField
                    id="password"
                    label="Пароль"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
            </FormControl>

            <FormControl>
                <TextField
                    id="confirmPassword"
                    label="Повторите пароль"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
            </FormControl>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
        </ContentContainer>
    </Modal>
}

export default SetAdminModal
