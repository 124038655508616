import { IDelete } from "./IDelete";
import { Balance } from "./Balance";
import { Project } from "./Business";

export interface User extends IDelete {
    id: string
    name: string
    login: string
    balances: Balance[]
    roles: UserRole[]
    projectIds: string[]
}

export enum UserRole {
    Manager = 'Manager',
    Paymaster = 'Paymaster',
}

export const UserRoleRus = {
    [UserRole.Manager]: 'Распорядитель',
    [UserRole.Paymaster]: 'Казначей',
}
