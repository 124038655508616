import React, { useEffect, useState } from 'react'
import { Container } from './styled'
import { Button, Divider, List, ListItem, ListItemText } from "@mui/material";
import { Expenses } from "../../../Models/Expenses";
import { apiGetExpenses } from "../../../Api/ExpensesApi";
import { AnalyticParameters } from "../../../Models/AnalyticParameters";
import { apiGetAllAnalytics } from "../../../Api/AnalyticsApi";
import ConstantHelper from "../../../Models/Constants";

const ManagerExpensesList = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [expenses, setExpenses] = useState<Expenses[]>([])
    const [analytics, setAnalytics] = useState<AnalyticParameters[]>([])

    useEffect(() => {
        // @ts-ignore
        let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username
        Promise.all([
            apiGetExpenses(userLogin)
                .then(resp => setExpenses(resp.data.map((x:any) => ({...x, operationDate: new Date(x.operationDate)})) ?? []))
                .catch(err => console.log(err)),
            apiGetAllAnalytics()
                .then(res => setAnalytics(res.data ?? []))
                .catch(err => console.log(err))
        ]).finally(() => setIsLoading(false))
        // @ts-ignore
        window?.Telegram?.WebApp?.expand()
    }, [])

    const getAnalyticName = (analyticId: string) => analytics.find(x => x.id === analyticId)?.name

    const handleClose = () => {
        // @ts-ignore
        window.Telegram?.WebApp?.close()
    }
    // @ts-ignore
    const bgColor = window.Telegram?.WebApp?.backgroundColor ?? 'white'
    if (isLoading) return <div>Loading...</div>
    return <Container style={{ backgroundColor: bgColor }}>
        <List sx={{ width: '100%', maxWidth: 360, backgroundColor: bgColor }}>
            {expenses.map(x => <>
                <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', color: 'text.primary' }}>
                    <ListItemText>{x.operationDate.toLocaleDateString()}</ListItemText>
                    <ListItemText>{ConstantHelper.Formatter.format(x.amount)}</ListItemText>
                    <ListItemText>{x.business.name} -&gt; {x.project.name}</ListItemText>
                    {x.analyticIds.map(analyticId => <ListItemText>{getAnalyticName(analyticId)}</ListItemText>)}
                    <ListItemText>{x.comment}</ListItemText>
                </ListItem>
                <Divider component="li" sx={{ borderColor: 'palette.divider' }}/>
            </>)}
        </List>
        <Button variant="outlined" fullWidth onClick={handleClose}>Закрыть</Button>
    </Container>
}

export default ManagerExpensesList
