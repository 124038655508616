import React, { useEffect, useState } from 'react'
import { Container } from "./styled";
import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { apiGetAllBusiness } from "../../../Api/BusinessApi";
import { Business } from "../../../Models/Business";
import { AnalyticParameters } from "../../../Models/AnalyticParameters";
import { apiGetAllAnalytics } from "../../../Api/AnalyticsApi";
import { apiCreateExpenses } from "../../../Api/ExpensesApi";
import * as yup from "yup";
import { useFormik } from "formik";
import MoneyInput from "../../../Components/MoneyInput";
import { apiGetUser } from "../../../Api/UserApi";
import { User } from "../../../Models/User";
import { MinValidDate } from "../../../Models/Settings";
import DatePicker from "../../../Components/DatePicker";

export interface ICreateExpensesFormData {
    business?: string
    project?: string
    amount?: number
    operationDate?: Date
    analyticParameters: string[]
    comment: string
}

const validationSchema = yup.object({
    business: yup.string().required('Выберите бизнес'),
    project: yup.string().required('Выберите проект'),
    amount: yup.number().min(1, 'Сумма должна быть больше 1 ₽').required('Введите сумму').typeError('Пожалуйста введите число'),
    operationDate: yup.date().required('Введите дату операции').min(MinValidDate, 'Дата должна быть больше 01.01.2000').typeError('Пожалуйста введите дату'),
    comment: yup.string(),
    analyticIds: yup.array(yup.string()),
})

const ManagerAddExpensesPage = ({}) => {
    const [business, setBusiness] = useState<Business[]>([])
    const [analytics, setAnalytics] = useState<AnalyticParameters[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState<User>();

    const formik = useFormik({
        initialValues: {
            business: '',
            project: '',
            amount: undefined,
            operationDate: undefined,
            comment: '',
            analyticIds: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // @ts-ignore
            let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username
            apiCreateExpenses({
                business: values.business,
                amount: values.amount ?? 0,
                comment: values.comment,
                managerLogin: userLogin,
                analyticParameters: values.analyticIds,
                project: values.project,
                operationDate: formik.values.operationDate as unknown as Date,
            }).finally(() =>             // @ts-ignore
                window.Telegram?.WebApp?.close())
        },
    });

    useEffect(() => {
        // @ts-ignore
        let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username

        Promise.all([
            apiGetUser(userLogin)
                .then(res => setUser(res)),
            apiGetAllBusiness()
                .then(res => setBusiness(res.data.filter((x: any) => !x.isDeleted) ?? []))
                .catch(err => console.log(err)),
            apiGetAllAnalytics()
                .then(res => setAnalytics(res.data.filter((x: any) => !x.isDeleted) ?? []))
                .catch(err => console.log(err))
        ]).finally(() => setIsLoading(false))

        // @ts-ignore
        window?.Telegram?.WebApp?.expand()

    }, [])

    const handleChangeAnalytics = (level: number) => (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`analyticIds[${level - 1}]`, value)
        formik.setFieldValue(`analyticIds[${level}]`, '')
        formik.setFieldValue(`analyticIds[${level + 1}]`, '')
    }

    const handleChangeBusiness = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`business`, value)
        formik.setFieldValue(`project`, '')
    }

    const handleChangeProject = (event: SelectChangeEvent) => {
        let value = event.target.value
        formik.setFieldValue(`project`, value)
        formik.setFieldValue(`analyticIds[0]`, '')
        formik.setFieldValue(`analyticIds[1]`, '')
        formik.setFieldValue(`analyticIds[2]`, '')
    }

    const handleChangeDate = (newValue: Date | null) => {
        if (newValue != null) {
            let date = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000)
            formik.setFieldValue("operationDate", date)
        }
    }

    const getAnalytics = (level: number, parentId: string | null = null): JSX.Element[] => {
        return (analytics?.filter(x => x.parentId === parentId && x.level === level && x.projectId === formik.values.project) ?? [])
            .map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
    }

    const userBusiness = business.filter(x => !x.isDeleted && x.projects.some(pr => user?.projectIds?.includes(pr.id) ?? false)) ?? []
    const selectedBusiness = business.find(x => x.id === formik.values.business)
    const userProjects = selectedBusiness?.projects.filter(x => !x.isDeleted && (user?.projectIds?.includes(x.id) ?? false)) ?? []

    if (isLoading) return <div>Loading...</div>
    return (<Container>
        <FormControl fullWidth error={formik.touched.business && Boolean(formik.errors.business)}>
            <InputLabel htmlFor="business">Бизнес</InputLabel>
            <Select
                id="business"
                variant="outlined"
                value={formik.values.business ?? ''}
                label="Бизнес"
                onChange={handleChangeBusiness}
            >
                {userBusiness.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
            <FormHelperText>{formik.touched.business && formik.errors.business}</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth
            disabled={!selectedBusiness} error={formik.touched.business && Boolean(formik.errors.business)}
        >
            <InputLabel htmlFor="project">Проект</InputLabel>
            <Select
                id="project"
                variant="outlined"
                value={formik.values.project ?? ''}
                label="Проект"
                onChange={handleChangeProject}
            >
                {userProjects.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
            <FormHelperText>{formik.touched.project && formik.errors.project}</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
            <MoneyInput
                id="amount"
                label="Сумма"
                value={formik.values.amount}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]', }}
                InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
                ChangeCallback={(val: number | undefined) => formik.setFieldValue("amount", val)}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
            />
        </FormControl>
        <FormControl fullWidth error={formik.touched.operationDate && Boolean(formik.errors.operationDate)}>
            <DatePicker
                label="Дата операции"
                value={formik.values.operationDate ?? null}
                onChange={date => formik.setFieldValue("operationDate", date)}
            />
            <FormHelperText>{formik.touched.operationDate && formik.errors.operationDate}</FormHelperText>
        </FormControl>
        <FormControl fullWidth disabled={!formik.values.project}>
            <InputLabel htmlFor="analytics1">Аналитика 1</InputLabel>
            <Select
                variant="outlined"
                id="analytics1"
                value={formik.values.analyticIds[0] ?? ''}
                label="Аналитика 1"
                onChange={handleChangeAnalytics(1)}
            >
                {getAnalytics(1)}
            </Select>
        </FormControl>
        <FormControl fullWidth disabled={!formik.values.analyticIds[0]}>
            <InputLabel htmlFor="analytics2">Аналитика 2</InputLabel>
            <Select
                variant="outlined"
                id="analytics2"
                value={formik.values.analyticIds[1] ?? ''}
                label="Аналитика 2"
                onChange={handleChangeAnalytics(2)}
            >
                {getAnalytics(2, formik.values.analyticIds[0])}
            </Select>
        </FormControl>

        <FormControl fullWidth disabled={!formik.values.analyticIds[1]}>
            <InputLabel htmlFor="analytics1">Аналитика 3</InputLabel>
            <Select
                variant="outlined"
                id="analytics3"
                value={formik.values.analyticIds[2] ?? ''}
                label="Аналитика 3"
                onChange={handleChangeAnalytics(3)}
            >
                {getAnalytics(3, formik.values.analyticIds[1])}
            </Select>
        </FormControl>
        <FormControl>
            <TextField
                id="comment"
                label="Комментарий"
                value={formik.values.comment}
                onChange={formik.handleChange}
                multiline
                rows={4}
                variant="outlined"
            />
        </FormControl>

        <Button id="submit_button" variant="outlined" fullWidth onClick={() => formik.handleSubmit()}>Отправить</Button>
    </Container>)

};

export default ManagerAddExpensesPage
