import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  & > * {
    margin-top: 30px;
  }
`
