import React, { useEffect, useState } from 'react'
import { Container } from './styled'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { User } from "../../../Models/User";
import { apiGetManagers } from "../../../Api/UserApi";
import { Button } from "@mui/material";


const columns: GridColDef[] = [
    { field: 'login', headerName: 'Логин' },
    { field: 'name', headerName: 'Имя' },
    { field: 'balance', headerName: 'Баланс', type: 'number', width: 150 },
];

const ManagerBalancesListPage = () => {
    const [managers, setManagers] = useState<User[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        apiGetManagers()
            .then(resp => setManagers(resp.data ?? []))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
        // @ts-ignore
        window?.Telegram?.WebApp?.expand()
    }, [])

    const handleClose = () => {
        // @ts-ignore
        window.Telegram?.WebApp?.close()
    }
    return <Container>
        <DataGrid
            rows={managers}
            columns={columns}
            autoHeight={true}
            hideFooter={true}
            loading={isLoading}
        />
        <Button variant="outlined" fullWidth onClick={handleClose}>Закрыть</Button>
    </Container>
}

export default ManagerBalancesListPage
