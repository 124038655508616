import React, { useState } from 'react'
import { TextField } from "@mui/material";
import NumberFormat, { NumberFormatValues, SourceInfo } from "react-number-format";
import { OutlinedTextFieldProps } from "@mui/material/TextField/TextField";

interface IMoneyInputProps extends OutlinedTextFieldProps {
    ChangeCallback: (value: number | undefined) => void
}

const MoneyInput = ({ ChangeCallback, ...props }: any) => {
    const [displayValue, setDisplayValue] = useState<string>(props.value ?? '')
    const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
        setDisplayValue(values.formattedValue)
        ChangeCallback(Number(values.value) || undefined)
    }
    return <NumberFormat
        {...props}
        customInput={TextField}
        isNumericString={true}
        thousandSeparator=" "
        value={displayValue}
        onValueChange={handleChange}
    />
}

export default MoneyInput
