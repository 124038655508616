import axios from 'axios'
import { User, UserRole } from "../Models/User";

export const apiGetManagers = () => axios.get("/api/user/managers")

export const apiGetUsers = () => axios.get("/api/user/all")

export const apiGetUser = (login: string): Promise<User> => axios.get(`/api/user/${login}`).then(res => res.data)

export const apiSetUser = (user: ISetUser) => axios.post(`/api/user/set`, user)

export const apiToggleDeleteUser = (id: string) => axios.post(`/api/user/${id}/toggleIsDelete`)

export const apiUpdateUsersBalance = () => axios.put(`/api/user/updateBalance`)

export interface ISetUser {
    id?: string
    login: string
    name: string
    roles: UserRole[]
    projectIds: string[]
}
