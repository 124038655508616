import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button } from "@mui/material";
import { Transfer, TransferStatus, TransferStatusRus } from "../../../Models/Transfer";
import { apiGetAllTransfers, apiToggleDeleteTransfer } from "../../../Api/TransferApi";
import SetTransferModal from "./SetTransfer";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";
import Table, { DefaultDateFilters, DefaultNumberFilters, DefaultStringFilters } from "../../../Components/Table";
import { CellExpand } from "../../../Components/CellExpand";
import ConstantHelper from "../../../Models/Constants";
import { Business, Project, ProjectWithBusiness } from "../../../Models/Business";
import { apiGetAllBusiness, apiGetAllProjectWithBusiness } from "../../../Api/BusinessApi";

interface ModalState {
    open: boolean
    editModel?: Transfer
}

const TransfersPage = () => {
    const [transactions, setTransactions] = useState<Transfer[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })
    const [business, setBusiness] = useState<Business[]>([])

    useEffect(() => loadData(), [])

    const columns = useMemo<MRT_ColumnDef<Transfer>[]>(() => [
        {
            id: 'transferDate',
            accessorKey: 'transferDate',
            header: 'Дата транзакции',
            accessorFn: (row) => new Date(row.transferDate),
            Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(),
            size: 200,
            meta: 'data',
        },
        {
            id: 'receivingDate',
            accessorKey: 'receivingDate',
            header: 'Дата получения',
            accessorFn: (row) => row.receivingDate ? new Date(row.receivingDate) : null,
            Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleString(),
            size: 200,
            meta: 'data',
        },
        {
            id: 'sender',
            accessorKey: 'sender',
            header: 'Отправитель',
            accessorFn: (value: Transfer) => `${value.sender.name}`,
            meta: 'string',
        },
        {
            id: 'senderBusiness',
            header: 'Бизнес списания',
            accessorFn: (value: Transfer) => `${getBusinessName(value.senderProject)}`,
            meta: 'string',
        },
        {
            id: 'senderProject',
            accessorKey: 'senderProject',
            header: 'Проект списания',
            accessorFn: (value: Transfer) => `${value.senderProject.name}`,
            meta: 'string',
        },
        {
            id: 'recipient',
            accessorKey: 'recipient',
            header: 'Получатель',
            accessorFn: (value: Transfer) => `${value.recipient.name}`,
            meta: 'string',
        },
        {
            id: 'recipientBusiness',
            header: 'Бизнес получения',
            accessorFn: (value: Transfer) => `${getBusinessName(value.recipientProject)}`,
            meta: 'string',
        },
        {
            id: 'recipientProject',
            accessorKey: 'recipientProject',
            header: 'Проект получения',
            accessorFn: (value: Transfer) => `${value.recipientProject.name}`,
            meta: 'string',
        },
        {
            id: 'amount',
            accessorKey: 'amount',
            header: 'Сумма',
            Cell: ({ cell }) => ConstantHelper.Formatter.format(cell.getValue<number>()),
            size: 150,
            meta: 'number',
        },
        {
            id: 'comment',
            accessorKey: 'comment',
            header: 'Комментарий',
            Cell: ({ cell, table }) => <CellExpand value={cell.getValue()} width={200}/>,
            size: 210,
            meta: 'string',
        },
        {
            id: 'status',
            accessorKey: 'status',
            header: 'Статус',
            Cell: ({ cell }) => TransferStatusRus[cell.getValue<TransferStatus>()] ?? 'Неизвестный статус',
            filterSelectOptions: [
                { text: TransferStatusRus[TransferStatus.Created], value: TransferStatus.Created },
                { text: TransferStatusRus[TransferStatus.Confirmed], value: TransferStatus.Confirmed },
                { text: TransferStatusRus[TransferStatus.Rejected], value: TransferStatus.Rejected },
            ],
            size: 150,
        },
    ], [business])

    const handleEditCell = (item: Transfer) => {
        setModalState({ open: true, editModel: item })
    }

    const handleToggleDeleteItem = (item: Transfer) => {
        apiToggleDeleteTransfer(item.id).then(() => loadData()).catch(err => console.log(err))
    }

    const getBusinessName = (project: Project): string => {
        let item = business.find(x => x.id === project.businessId)
        return item?.name ?? ''
    }

    const loadData = () => {
        Promise.all([
            apiGetAllTransfers()
                .then(resp => setTransactions(resp.data.map((x: any) => ({ ...x, transferDate: new Date(x.transferDate) })) ?? []))
                .catch(err => console.log(err)),
            apiGetAllBusiness()
                .then(res => setBusiness(res.data ?? []))
        ]).finally(() => setIsLoading(false))
    }

    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }

    const formatDataToCsv = (data: Transfer[]) => {
        return data.map(x => ({
            id: x.id,
            transferDate: new Date(x.transferDate).toLocaleDateString(),
            receivingDate: x.receivingDate ? new Date(x.receivingDate).toLocaleDateString() : '',
            sender: x.sender.name,
            senderBusiness: getBusinessName(x.senderProject),
            senderProject: x.senderProject.name,
            recipient: x.recipient.name,
            recipientBusiness: getBusinessName(x.recipientProject),
            recipientProject: x.recipientProject.name,
            amount: x.amount,
            comment: x.comment,
            status: TransferStatusRus[x.status],
            isDeleted: x.isDeleted ? 'Да' : 'Нет'
        }))
    }

    if(isLoading) return <div>Loading...</div>

    return <Container>
        <Button
            variant="outlined"
            sx={{ width: '250px' }}
            onClick={() => setModalState({ open: true })}
        >
            Добавить Трансфер
        </Button>
        <Table
            columns={columns}
            data={transactions}
            csvDataMapper={formatDataToCsv}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
            initialState={{ sorting: [{ id: 'transferDate', desc: true }] }}
        />

        {modalState.open && <SetTransferModal isOpen={modalState.open} transfer={modalState.editModel} onClose={closeModal}/>}
    </Container>
}

export default TransfersPage
