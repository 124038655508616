import React from 'react'
import ConstantHelper from "../../Models/Constants";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

interface IDatePickerProps {
    label: string
    value: Date | null
    onChange: (newDate: Date) => void
    type?: 'desktop' | 'mobile'
}

const DatePicker = ({label, value, onChange, type = 'desktop'}:IDatePickerProps) => {
    const handleChangeDate = (newValue: Dayjs | null) => {
        if (newValue != null) {
            let newValueDate = newValue.toDate()
            let date = new Date(newValueDate.getTime() - newValueDate.getTimezoneOffset() * 60000)
            onChange(date)
        }
    }

    if(type === 'desktop')
        return <DesktopDatePicker
            label={label}
            value={dayjs(value)}
            onChange={handleChangeDate}
            minDate={dayjs(ConstantHelper.MaxDayBefore)}
            maxDate={dayjs(ConstantHelper.MaxDayAfter)}
        />

    return <MobileDatePicker
        label={label}
        value={dayjs(value)}
        onChange={handleChangeDate}
        minDate={dayjs(ConstantHelper.MaxDayBefore)}
        maxDate={dayjs(ConstantHelper.MaxDayAfter)}
    />
}

export default DatePicker
