import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { User, UserRole } from "../../../Models/User";
import { apiGetManagers } from "../../../Api/UserApi";
import { apiCreateTransfer } from "../../../Api/TransferApi";
import * as yup from "yup";
import { useFormik } from "formik";
import MoneyInput from "../../../Components/MoneyInput";
import { apiGetAllProjectWithBusiness } from "../../../Api/BusinessApi";
import { ProjectWithBusiness } from "../../../Models/Business";
import { MinValidDate } from "../../../Models/Settings";
import DatePicker from "../../../Components/DatePicker";

export interface SendMoneyPageData {
    recipientLogin?: string
    amount?: number
    transferDate?: Date
}

interface IProps {
    senderRole: UserRole
}

const validationSchema = yup.object({
    recipient: yup.string().required('Выберите получателя'),
    recipientRole: yup.mixed<UserRole>().oneOf(Object.values(UserRole)),
    senderProject: yup.string().required('Выберите проект'),
    recipientProject: yup.string().required('Выберите проект'),
    amount: yup.number().min(1, 'Сумма должна быть больше 1 ₽').required('Введите сумму').typeError('Пожалуйста введите число'),
    transferDate: yup.date().required('Введите дату операции').min(MinValidDate, 'Дата должна быть больше 01.01.2000').typeError('Пожалуйста введите дату'),
    comment: yup.string(),
})

const SendMoneyPage = ({ senderRole }: IProps) => {
    const [managers, setManagers] = useState<User[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [projects, setProjects] = useState<ProjectWithBusiness[]>([])
    const formik = useFormik({
        initialValues: {
            recipient: '',
            senderProject: '',
            recipientProject: '',
            amount: null,
            transferDate: undefined,
            recipientRole: UserRole.Manager,
            comment: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => handleSend(),
    });

    useEffect(() => {
        Promise.all([
            apiGetManagers()
                .then(resp => {
                    let result = resp.data ?? []
                    setManagers(result.filter((x: User) => !x.isDeleted))
                })
                .catch(err => console.log(err)),
            apiGetAllProjectWithBusiness()
                .then(res => setProjects(res.filter((x: any) => !x.isDeleted) ?? []))
                .catch(err => console.log(err)),
        ]).finally(() => setIsLoading(false))
        // @ts-ignore
        window?.Telegram?.WebApp?.expand()
    }, [])

    const senderProjects = useMemo<ProjectWithBusiness[]>(() => {
        // @ts-ignore
        let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username
        let sender = managers.find(x => x.login === userLogin)
        return projects.filter(x => sender?.projectIds?.includes(x.id) ?? false) ?? []
    }, [projects, managers])

    const recipientProjects = useMemo<ProjectWithBusiness[]>(() => {
        let recipient = managers.find(x => x.login === formik.values.recipient)
        return projects.filter(x => recipient?.projectIds?.includes(x.id) ?? false) ?? []
    }, [formik.values.recipient, projects, managers])

    const handleSend = async () => {
        // @ts-ignore
        let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username
        await apiCreateTransfer({
            recipientLogin: formik.values.recipient,
            senderLogin: userLogin,
            senderProject: formik.values.senderProject,
            recipientProject: formik.values.recipientProject,
            transferDate: formik.values.transferDate as unknown as Date,
            senderRole: senderRole,
            recipientRole: UserRole.Manager,
            amount: formik.values.amount ?? 0,
            comment: formik.values.comment,
        })

        // @ts-ignore
        window.Telegram?.WebApp?.close()
    }

    const handleChangeSelect = (field: string) => (event: SelectChangeEvent) => {
        const value = event.target.value
        formik.setFieldValue(field, value)
    };

    const handleChangeDate = (newValue: Date | null) => {
        if (newValue != null) {
            let date = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000)
            formik.setFieldValue("transferDate", date)
        }
    };

    const getManagers = () => {
        // @ts-ignore
        let userLogin = window.Telegram?.WebApp?.initDataUnsafe?.user?.username
        return managers.filter(x => (formik.values.recipientRole === senderRole ? x.login !== userLogin : true))
    }

    if (isLoading) return <div>Loading...</div>

    return (<Container>
        <FormControl
            fullWidth
            error={formik.touched.senderProject && Boolean(formik.errors.senderProject)}
        >
            <InputLabel htmlFor="senderProject">Проект списания</InputLabel>
            <Select
                id="senderProject"
                variant="outlined"
                value={formik.values.senderProject ?? ''}
                label="Проект списания"
                onChange={handleChangeSelect('senderProject')}
            >
                {senderProjects.map(x => <MenuItem key={x.id} value={x.id}>{x.business.name} - {x.name}</MenuItem>)}
            </Select>
            <FormHelperText>{formik.touched.senderProject && formik.errors.senderProject}</FormHelperText>
        </FormControl>

        <FormControl fullWidth error={formik.touched.recipient && Boolean(formik.errors.recipient)}>
            <InputLabel htmlFor="recipient">Получатель</InputLabel>
            <Select
                variant="outlined"
                id="recipient"
                value={formik.values.recipient}
                label="Получатель"
                onChange={handleChangeSelect('recipient')}
            >
                {getManagers().map(x => <MenuItem key={x.id} value={x.login}>{x.name}</MenuItem>)}
            </Select>
            <FormHelperText>{formik.touched.recipient && formik.errors.recipient}</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth
            error={formik.touched.recipientProject && Boolean(formik.errors.recipientProject)}
        >
            <InputLabel htmlFor="recipientProject">Проект получения</InputLabel>
            <Select
                id="recipientProject"
                variant="outlined"
                value={formik.values.recipientProject ?? ''}
                label="Проект получения"
                onChange={handleChangeSelect('recipientProject')}
            >
                {recipientProjects.map(x => <MenuItem key={x.id} value={x.id}>{x.business.name} - {x.name}</MenuItem>)}
            </Select>
            <FormHelperText>{formik.touched.recipientProject && formik.errors.recipientProject}</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
            <MoneyInput
                id="amount"
                label="Сумма"
                value={formik.values.amount}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]', }}
                InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
                ChangeCallback={(val: number | undefined) => formik.setFieldValue("amount", val)}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
            />
        </FormControl>
        <FormControl error={formik.touched.transferDate && Boolean(formik.errors.transferDate)}>
            <DatePicker
                label="Дата операции"
                value={formik.values.transferDate ?? null}
                onChange={date => formik.setFieldValue("transferDate", date)}
            />
            <FormHelperText>{formik.touched.transferDate && formik.errors.transferDate}</FormHelperText>
        </FormControl>
        <FormControl>
            <TextField
                id="comment"
                label="Комментарий"
                value={formik.values.comment}
                onChange={formik.handleChange}
                multiline
                rows={4}
                variant="outlined"
            />
        </FormControl>

        <Button variant="outlined" fullWidth onClick={() => formik.handleSubmit()}>Отправить</Button>
    </Container>)
};

export default SendMoneyPage
