import axios from 'axios'
import { UserRole } from "../Models/User";
import { TransferStatus } from "../Models/Transfer";

export const apiCreateTransfer = (data: CreateTransferModel,) =>
    axios.post("/api/transfer/create", data)

export const apiGetAllTransfers = () => axios.get(`/api/transfer/all`)

export const apiEditTransfer = (data: EditTransferModel,) =>
    axios.post("/api/transfer/edit", data)

export const apiToggleDeleteTransfer = (id: string) => axios.post(`/api/transfer/${id}/toggleIsDelete`)

interface CreateTransferModel {
    senderLogin: string
    recipientLogin: string
    senderProject: string
    recipientProject: string
    amount: number
    transferDate: Date
    senderRole: UserRole
    recipientRole: UserRole
    comment: string
}

interface EditTransferModel {
    id: string
    status: TransferStatus
    comment: string
    transferDate: Date
}
