import React, { useEffect, useState } from 'react'
import { Container } from "./styled";
import { Settings } from "../../../Models/Settings";
import { apiGetSettings, apiSetSettings } from "../../../Api/SettiingsApi";
import * as yup from "yup";
import { Button, FormControl, TextField } from "@mui/material";
import { useFormik } from "formik";
import { apiSetAdmin } from "../../../Api/AdminApi";

const validationSchema = yup.object({
    maxDayAfter : yup.number().nullable().min(1, "Должно быть больше 0").max(100, "Должно быть меньше 100"),
    maxDayBefore: yup.number().nullable().min(1, "Должно быть больше 0").max(100, "Должно быть меньше 100"),
})

const SettingsPage = () => {
    const [settings, setSettings] = useState<Settings>({})
    useEffect(() => {
        apiGetSettings().then(res => {
            formik.setFieldValue("maxDayAfter", res.data.maxDayAfter)
            formik.setFieldValue("maxDayBefore", res.data.maxDayBefore)
        })

    }, [])
    const formik = useFormik({
        initialValues: {
            maxDayAfter: 0,
            maxDayBefore: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiSetSettings(values.maxDayAfter, values.maxDayBefore)
        },
    });
    return <Container>
        <FormControl>
            <TextField
                id="maxDayBefore"
                label="Доступные дни перед сегодняшним"
                value={formik.values.maxDayBefore}
                onChange={formik.handleChange}
                variant="outlined"
                type="number"
                error={formik.touched.maxDayBefore && Boolean(formik.errors.maxDayBefore)}
                helperText={formik.touched.maxDayBefore && formik.errors.maxDayBefore}
            />
        </FormControl>
        <FormControl>
            <TextField
                id="maxDayAfter"
                label="Доступные дни после сегодняшним"
                value={formik.values.maxDayAfter}
                onChange={formik.handleChange}
                variant="outlined"
                type="number"
                error={formik.touched.maxDayAfter && Boolean(formik.errors.maxDayAfter)}
                helperText={formik.touched.maxDayAfter && formik.errors.maxDayAfter}
            />
        </FormControl>
        <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
    </Container>
}

export default SettingsPage
