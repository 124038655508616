import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button } from "@mui/material";
import { AnalyticParameters } from "../../../../Models/AnalyticParameters";
import { apiGetAllAnalytics, apiToggleDeleteAnalytic } from "../../../../Api/AnalyticsApi";
import SetAnalyticModal from "./SetAnalytic";
import Table, { DefaultNumberFilters, DefaultStringFilters } from "../../../../Components/Table";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";

interface ModalState {
    open: boolean
    editModel?: AnalyticParameters
}

const AnalyticsTab = () => {
    const [analytics, setAnalytics] = useState<AnalyticParameters[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })

    useEffect(() => loadData(), [])

    const columns = useMemo<MRT_ColumnDef<AnalyticParameters>[]>(() => [
        {
            id: 'name',
            accessorKey: 'name',
            header: 'Параметр',
            meta: 'string',
        },
        {
            id: 'level',
            accessorKey: 'level',
            header: 'Уровень',
            size: 100,
            meta: 'number',
        },
        {
            id: 'projectId',
            accessorKey: 'projectId',
            header: 'Проект',
            accessorFn: (value: AnalyticParameters) => value.project.name,
            meta: 'string',
        },
        {
            id: 'parentId',
            accessorKey: 'parentId',
            header: 'Родитель',
            accessorFn: (value: AnalyticParameters) => getParentName(value.parentId),
            meta: 'string',
        },
    ], [analytics])

    const handleEditCell = (item: AnalyticParameters) => {
        setModalState({ open: true, editModel: item })
    }

    const handleToggleDeleteItem = (item: AnalyticParameters) => {
        apiToggleDeleteAnalytic(item.id).then(() => loadData()).catch(err => console.log(err))
    }

    const getParentName = (parentId?: string) => {
        return analytics.find(x => x.id === parentId)?.name ?? ''
    }

    const loadData = () => {
        apiGetAllAnalytics()
            .then(res => setAnalytics(res.data ?? []))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }

    return <Container>
        <Button
            variant="outlined"
            sx={{ width: '250px', marginBottom: '20px' }}
            onClick={() => setModalState({ open: true })}
        >Добавить параметр</Button>
        <Table
            columns={columns}
            data={analytics}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
        />
        {modalState.open && <SetAnalyticModal isOpen={modalState.open} analytic={modalState.editModel} onClose={closeModal}/>}
    </Container>
}

export default AnalyticsTab
