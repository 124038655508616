import axios from 'axios'
import {ICreateExpensesFormData} from "../Pages/Bot/ManagerAddExpensesPage";

export const apiCreateRevenue = (data: ApiCreateRevenue) =>
    axios.post("/api/revenue/create", data)

export const apiEditRevenue = (data: ApiEditRevenue) =>
    axios.post("/api/revenue/edit", data)

export const apiGetAllRevenues = () => axios.get(`/api/revenue/all`)

export const apiToggleDeleteRevenue = (id: string) => axios.post(`/api/revenue/${id}/toggleIsDelete`)

export interface ApiCreateRevenue {
    userLogin: string,
    business: string
    project: string
    date: Date,
    amount: number,
    comment: string,
}

interface ApiEditRevenue {
    id: string
    date: Date,
    comment: string
}
