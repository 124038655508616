import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button } from "@mui/material";
import { Business } from "../../../../Models/Business";
import { apiGetAllBusiness, apiToggleDeleteBusiness } from "../../../../Api/BusinessApi";
import SetBusinessModal from "./SetBusiness";
import Table, { DefaultStringFilters } from "../../../../Components/Table";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";
import { User } from "../../../../Models/User";
import ConstantHelper from "../../../../Models/Constants";
import _ from "lodash";

interface ModalState {
    open: boolean
    editModel?: Business
}

const BusinessTab = () => {
    const [business, setBusiness] = useState<Business[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })

    useEffect(() => loadData(), [])

    const columns = useMemo<MRT_ColumnDef<Business>[]>(() => [
        {
            id: 'name',
            accessorKey: 'name',
            header: 'Бизнес',
            meta: 'string',
        },
        {
            id: 'balances',
            header: 'Баланс',
            accessorFn: (row) => _.flatMap(row.projects, project => project.balances).reduce((a, b) => a + b.amount, 0),
            Cell: ({ cell }) => ConstantHelper.Formatter.format(cell.getValue<number>()),
            meta: 'number',
            size: 150,
        },

    ], [])
    const handleEditCell = (item: Business) => {
        setModalState({ open: true, editModel: item })
    }

    const handleToggleDeleteItem = (item: Business) => {
        apiToggleDeleteBusiness(item.id).then(() => loadData()).catch(err => console.log(err))
    }

    const loadData = () => {
        apiGetAllBusiness()
            .then(resp => setBusiness(resp.data ?? []))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }

    return <Container>
        <Button
            variant="outlined"
            sx={{ width: '250px', marginBottom: '20px' }}
            onClick={() => setModalState({ open: true })}
        >Добавить бизннес</Button>
        <Table
            columns={columns}
            data={business}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
        />
        {modalState.open && <SetBusinessModal isOpen={modalState.open} business={modalState.editModel} onClose={closeModal}/>}
    </Container>
}

export default BusinessTab
