import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const TabContainer = styled.div`
  margin-bottom: 40px;
  .MuiTabs-flexContainer > *:nth-child(n + 2) {
    margin-left: 20px;
  }
`
